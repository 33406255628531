import React, { useState, useRef, useEffect } from "react";
import Icon from "react-icons-kit";
import { chevronDown } from 'react-icons-kit/ionicons/chevronDown'
import { chevronUp } from 'react-icons-kit/ionicons/chevronUp'
import { useSelector } from "react-redux";

const CustomDropdown = ({ children, title = "My Head", hidetext = false }) => {
    const user = useSelector(state => state?.user);
    const [isOpen, setOpen] = useState(false);
    const contentRef = useRef(null);
    const [contentHeight, setContentHeight] = useState(0);

    const toggleDropdown = () => {
        setOpen(!isOpen);
    };

    useEffect(() => {
        setContentHeight(contentRef.current.scrollHeight + 10);
    }, [isOpen]);

    useEffect(() => {
        if (window.location.href.includes('category')) {
            setOpen(true)
        }
    }, [])

    return (
        <div className="mb-2">
            <div onClick={toggleDropdown} style={{ borderRadius: 2, backgroundColor: "rgb(115 222 236)", padding: 10, display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }} >
                <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%', }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={user?.data?.photo || require("../assets/user.png")} width={25} height={25} style={{ objectFit: 'contain' }} alt="User Image" />
                        </div>
                        <div className={`${hidetext ? "d-none d-md-block" : ""}`} style={{ display: hidetext ? "none" : 'initial', margin: '0 10px', color: "#000" }}>{user.data.first_name}'s Head</div>
                    </div>
                    <div className={`${hidetext ? "d-none d-md-block" : ""}`} >
                        <Icon icon={isOpen ? chevronUp : chevronDown} />
                    </div>
                </div>
            </div>
            <div className="ms-sm-0 ms-md-4" style={{ height: isOpen ? contentHeight : 0, transition: 'height 0.2s ease-in-out', overflow: 'hidden' }}>
                <div ref={contentRef}>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default CustomDropdown;

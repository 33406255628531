import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Slider from "react-slick";
import Api from "../services/Api";
import ModelListing from "../components/ModelListing";
import { setQuestsdata, resetQuests, removeQuestsdata } from '../actions/questActions';
import { toast } from "react-toastify";
import Footer from "../components/footer";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import QuestListing from "../components/QuestLisitng";
import NestListing from "../components/Nestlising";
import InfiniteScroll from "react-infinite-scroller";
import Buttons from "../components/Buttons";
import { Modal } from 'react-bootstrap';
import CheckBox from "../components/CheckBox";

const WillHomepage = () => {

    let _settings = {
        infinite: false,
        dots: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: false,
        draggable: true,
        swipe: true,
        arrows: false,
        easing: 'ease',
        responsive: [
            {
                breakpoint: 1386,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    };

    let width = window.innerWidth;
    let location = window.location.href;

    const navigate = useNavigate();

    const sliderref = useRef();
    const pageRef = useRef();
    const hasMoreRef = useRef();

    const [data, setData] = useState([]);
    const [characterCategory, setCharacterCategory] = useState(false);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [addingItem, setAddingItem] = useState(false);
    // const [itemsData, setItemsData] = useState(false);
    const [quest, setQuests] = useState([]);
    const [nestData, setNestData] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState('new');
    const [filterLoading, setFilteringLoading] = useState(false);
    const [mindVal, setMindval] = useState('')
    const [sending, setSending] = useState(false)
    const [readmore, setReadMore] = useState([]);
    const [itemPhoto, setItemPhoto] = useState({})
    const [termsAccepted, setTermsAcceptance] = useState(false)
    const [termsErr, setTermsError] = useState(false);
    const [itemsdata, setItemsData] = useState({})
    const [submitting, setSubmitting] = useState(false)
    const [isPublic, setPublic] = useState(false)
    const [modal, setModel] = useState(false)
    const [imagesToSelect, setImagesToSelect] = useState([])


    const dispatch = useDispatch();

    const isLogged = useSelector((state) => state.user.isLogged);
    const selected = useSelector((state) => state.quests.selected);
    const default_character = useSelector((state) => state.characotors.default_charactor);
    const selectedQuests = useSelector(state => state.quests.selected);

    const onCategorySelect = (item, index, type = 'C') => {

        if (type == 'S' && 0) { //       remove    || 0  condition for default functionality
            return;
        }

        let winwid = window.screen.width;
        let _itemsperslide = winwid > 1025 ? 5 : winwid < 1024 ? 4 : winwid < 768 ? 3 : winwid < 480 ? 2 : 2

        if (categories.length > _itemsperslide) {
            sliderref?.current?.slickGoTo(Math.floor(index + 1 / _itemsperslide))
        }

        setCurrentSlide(index || 0);
        setAddingItem(false);
        setItemsData({})
        item.index = index;
        setCharacterCategory(item)

        if (item.is_level == 'Y') {

            Api.get(`chat/quests?limit=2&page=1`)
                .then((res) => {
                    if (res.success) {
                        if (res?.data) {
                            if (res.data.next) {
                                pageRef.current = 2
                            }
                            hasMoreRef.current = res.data.next
                            setQuests(res.data.list)
                        }
                        setNestData(res?.data?.nest || [])
                    } else {
                        hasMoreRef.current = false
                    }
                }).catch(() => {
                    hasMoreRef.current = false
                })
                .finally(() => {
                    setLoading(false)
                })
        } else {
            Api.get(`chat-category/detail/${item.id}${selectedFilter && ("?filter=" + selectedFilter)} `)
                .then((res) => {
                    if (res.success) {
                        setData(res?.data[0].character || [])
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    const handlequest = (event) => {
        event.preventDefault();
        event.stopPropagation()
        if (selectedQuests.length) {
            if (!isLogged) {
                navigate('/login', { state: { from: "/create-level" } })
                // navigate('/' + route, { state: { from: from, message: message } })
            } else {
                navigate('/create-level', { state: { quests: [...selectedQuests] } })
            }
        }
    }

    const selectItemPhoto = (k, v) => {

        // if (type == 'default') {
        let data = { ...itemPhoto }
        data[k] = v;
        setItemPhoto(data)
        // } else {
        //   let data = { ...itemPhoto }
        //   data['photo'] = v;
        //   setItemPhoto(data)
        // }

    }

    const details = (e, ele) => {
        ele.preventDefault()
        navigate(`/models/${(e?.seo || 'model')}`)
    }

    const updateLike = (e) => {

        if (selected.find((item) => item.id == e.id)) {
            selected.filter((item) => item.id != e.id)
            dispatch(removeQuestsdata(e))
        } else {
            dispatch(setQuestsdata(e))
        }
    }

    const onSort = (id, sort) => {

        let _data = [...data]
        let index = _data.findIndex(item => item.id == id)

        if ((index == 0 && sort == "up") || (index == (_data.length - 1) && sort == 'down')) {
            toast.error(`No item is ${sort == "down" ? "below" : "above"}!`)
            return
        }

        Api.get("characters/set-order/" + id + "/" + sort)
            .then((res) => {
                if (res.success) {
                    if (sort == "up") {
                        let before = _data[index]
                        _data[index] = _data[index - 1]
                        _data[index - 1] = before
                    } else {
                        let before = _data[index]

                        _data[index] = _data[index + 1]
                        _data[index + 1] = before
                    }
                    setData(_data)
                } else {
                    toast.error(res?.message || "Some Error occured!")
                }
            })
            .catch((err) => {
                toast.error(err?.message || "Some Error occured!")
            })
    }

    const closeTermsModel = () => {
        setModel(false);
    }
    const hasItemImages = () => {
        let res = false
        imagesToSelect.map((item) => {
            if (item.category_id.includes(characterCategory.id)) {
                res = true
            }
        })
        return res
    }

    const handle_photos = () => {

        setModel(false)
    }
    const onPublicChange = () => {
        setPublic(!isPublic);
    }

    const onTermsChange = () => {
        setTermsError(false)
        setTermsAcceptance(!termsAccepted);
    }

    useEffect(() => {
        if (categories.length > 0) {
            onCategorySelect(categories[0], 0)
        }
    }, [categories])

    const submit_item = () => {

        if (!isLogged) {
            navigate('/login', { state: { from: "/", redirectdata: { images: itemPhoto, itemsData: itemsdata, characterCategory } } })
            // navigate('/login', { state: { from: location.pathname, redirectmessage: message } })
        }

        if (characterCategory.type != '2') {
            if (itemsdata?.thought && itemsdata.thought.trim() == "") {
                toast.error('Field cannot be empty!')
                return
            } else {
                // toast.error('Please Enter Both Toxic and Healthy Items!')
                setModel('submit');
                return
            }
        }
        if (!itemsdata?.toxic || !itemsdata?.healthy) {
            toast.error('Please Enter Both Toxic and Healthy Items!')
            return
        }
        setModel('submit');
    }

    const send = (e) => {

        e.preventDefault();
        if (mindVal.trim() == "" || sending) {
            return
        }

        if (!isLogged) {
            navigate('/login');
            return
        }

        setSending(true)

        let data = {}
        data["chat_id"] = 0
        data["character_id"] = default_character.id
        data["message"] = mindVal

        Api.post('chat/sendMessage', data)
            .then((resData) => {
                if (resData.success) {
                    setMindval('')
                    setSending(false)
                    navigate('/chat/' + default_character.id + "/" + resData.data.list.chat_id)
                } else {
                    toast.error(resData?.message || "Some error occured!")
                }
            })
            .catch((err) => {
                toast.error("Some error occured!")
            })
            .finally(() => {
                setSending(false)
            })

    }

    const handleaddItem = () => {
        setAddingItem(true)
    }
    const handleChange = (key, val) => {
        let data = { ...itemsdata };

        data[key] = val

        if (data[key].trim() == "") {
            delete data[key]
        }
        setItemsData(data)
    }

    const handleAddItem = () => {
        if (!termsAccepted) {
            setTermsError(true)
            return
        }
        setSubmitting(true);

        Api.post('images/save', {
            "image_id": { "default": itemPhoto?.photo?.id, "healthy": itemPhoto?.healthy?.id, "toxic": itemPhoto?.toxic?.id },
            "category_id": characterCategory.id,
            "description": { "default": itemsdata.thought, "healthy": itemsdata.healthy, "toxic": itemsdata.toxic },
            "is_public": isPublic ? 1 : 0
        })
            .then((res) => {
                if (res.success) {
                    toast.success("Added successfully!")
                }
            })
            .catch((err) => {
                toast.error(err.message || "Some Error Occured")

            })
            .finally(() => {
                setSubmitting(false)
                setAddingItem(false)
                setItemsData({})
                setModel(false)
                setPublic(false);
                setTermsAcceptance(false)
            })

    }

    const onFilterChange = (e) => {
        setSelectedFilter(e.target.value)
        setFilteringLoading(true)

        let url = characterCategory ? `chat-category/detail/${characterCategory.id}${selectedFilter && ("?filter=" + e.target.value)}` : `characters${isLogged ? "" : '/public'}/list?filter=${e.target.value}`
        Api.post(url)
            .then((res) => {
                if (res.success) {
                    if (characterCategory) {
                        setData(res.data[0].character)
                    } else {
                        setData(res.data)
                    }
                } else {
                }
            })
            .finally(() => {
                setFilteringLoading(false)
            })
    }

    const handleItemImage = () => {
        setModel('images');

    }

    const setWhatsyourMindval = (e) => {
        setMindval(e.target.value)
    }

    useEffect(() => {

        // dispatch({ type: "HEADER_STYLE", payload: { showCenterButton: false } })

        Api.get("images/list")
            .then((res) => {
                if (res.success) {
                    setImagesToSelect(res.data);
                }
            })
            .catch((err) => {
            })

        // return () => dispatch({ type: "HEADER_STYLE", payload: { showCenterButton: true } })
    }, [])

    useEffect(() => {
        if (characterCategory.id) {
            let itemsImages = [];

            imagesToSelect.map((item, i) => {
                if (item?.category_id?.includes(characterCategory?.id)) {
                    itemsImages.push(item);
                }
            })


            setItemPhoto({
                healthy: itemsImages[0],
                toxic: itemsImages[0],
                photo: itemsImages[0],
            })
        }
    }, [characterCategory])

    useEffect(() => {
        setLoading(true)
        let url = isLogged ? 'characters/list' : 'characters/public/list'
        let characters = Api.post(url)
        let _categories = Api.get('chat-category/list ')

        Promise.all([characters, _categories])
            // Promise.all([characters, categories])
            .then((res) => {
                if (res[0].success) {
                    setData(res[0].data)
                } else {
                    setData([])
                }
                if (res[1].success) {
                    setCategories(res[1].data);
                    setCharacterCategory(res[1].data[0], 0)
                } else {
                    setCategories([]);
                }
            })
            .catch((err) => {
                setData([])
            })
            .finally(() => {
                setLoading(false)
            })

        // return () => dispatch(resetQuests())
    }, [location])

    const loadMore = () => {
        // if (loading) return
        Api.get(`chat/quests?limit=2&page=${pageRef.current}&filter=${selectedFilter}`)
            .then((res) => {
                if (res.success) {
                    hasMoreRef.current = res.data.next
                    let _data = [...quest, ...res.data.list]
                    setQuests(_data)
                    pageRef.current++
                } else {
                    hasMoreRef.current = false
                }
            })
            .catch(() => {
                hasMoreRef.current = false
            })
    }

    return (
        <div>
            <Modal
                show={modal}
                centered
            >
                {modal == "images" ?
                    <>
                        <Modal.Header>
                            <h4 style={{ fontFamily: 'Dimbo' }}>Select Image</h4>
                            <div onClick={closeTermsModel} style={{ width: '25px', height: '25px', }} className="d-flex rounded-start-pill rounded-end-pill justify-content-center align-items-center cursor-pointer bg-dark" >
                                <img src={require('../assets/cross.png')} width={'50%'} height={'50%'} style={{ cursor: 'pointer' }} />
                            </div>
                        </Modal.Header>
                        {characterCategory.type == '2' ?
                            <div className='d-flex justify-content-around'>
                                <div>
                                    <div className='text-center my-2' style={{ fontFamily: "Dimbo" }} >Healthy Image</div>
                                    {imagesToSelect.map((item, index) => {
                                        if (!item.category_id.includes(characterCategory.id)) return
                                        return (
                                            <div key={index} onClick={() => selectItemPhoto("healthy", item)} style={{ boxSizing: 'border-box' }} className={`rounded m-2 border border-2 ${itemPhoto?.healthy?.id == item.id ? "border-dark" : " border-white"}`}>
                                                <img src={item.image} style={{ width: '60px', height: '60px', objectFit: 'contain' }} />
                                            </div>)
                                    }
                                    )}
                                </div>
                                <div>
                                    <div className='text-center my-2' style={{ fontFamily: "Dimbo" }} >Toxic Image</div>
                                    {imagesToSelect.map((item, index) => {
                                        if (!item.category_id.includes(characterCategory.id)) return
                                        return (<div key={index} onClick={() => selectItemPhoto("toxic", item)} style={{ boxSizing: 'border-box' }} className={`rounded m-2 border border-2 ${itemPhoto?.toxic?.id == item.id ? "border-dark" : " border-white"}`}>
                                            <img src={item.image} style={{ width: '60px', height: '60px', objectFit: 'contain' }} />
                                        </div>)
                                    }
                                    )}
                                    {!hasItemImages() && <div className='text-center py-2'>No image available</div>}
                                </div>
                            </div>
                            :
                            <div className="my-2">
                                {imagesToSelect.map((item, index) => {
                                    if (!item.category_id.includes(characterCategory.id)) return
                                    else if (item.image) {
                                        return (
                                            <div key={index} onClick={() => selectItemPhoto("photo", item)} style={{ boxSizing: 'border-box', width: "68px" }} className={`rounded m-2 border border-2 m-auto ${itemPhoto?.photo?.id == item.id ? "border-dark" : " border-white"}`}>
                                                <img src={item.image} style={{ width: '60px', height: '60px', objectFit: 'contain' }} />
                                            </div>
                                        )
                                    }

                                })}
                                {!hasItemImages() && <div className='text-center py-2' >No image available</div>}
                            </div>
                        }
                        <Modal.Footer>
                            <Buttons title={'Save'} onClick={handle_photos} style={{ height: '50px', lineHeight: '50px' }} />
                            {/* <Buttons title={'Create'} style={{ height: '40px', lineHeight: '40px', margin: 0 }} loading={savingCharacter} onClick={saveCharacter} /> */}
                        </Modal.Footer>
                    </>
                    :
                    <div>
                        <form >
                            <Modal.Header>
                                <h4 style={{ fontFamily: 'Dimbo' }}>Add Item</h4>
                                <div onClick={closeTermsModel} style={{ width: '25px', height: '25px', }} className="d-flex rounded-start-pill rounded-end-pill justify-content-center align-items-center cursor-pointer bg-dark" >
                                    <img src={require('../assets/cross.png')} width={'50%'} height={'50%'} style={{ cursor: 'pointer' }} />
                                </div>
                            </Modal.Header>
                            <Modal.Body>
                                {/* <h5 style={{ fontFamily: 'Dimbo' }} >Terms and conditions</h5> */}
                                {termsErr && <div className="border border-danger rounded px-2 py-1 mb-2 text-danger">Please accept the Terms and Conditions!</div>}
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <CheckBox value={isPublic} onChange={onPublicChange} /> <div>Set as Public</div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <CheckBox value={termsAccepted} onChange={onTermsChange} /> <div>I accept the <a style={{ color: '#000' }} href="https://nestheads.com/term-of-use/" target='_blank'>Terms and Conditions</a>.</div>
                                </div>
                            </Modal.Body>
                        </form>
                        <Modal.Footer>
                            <Buttons title={'Add Item'} style={{ height: '40px', lineHeight: '40px', margin: 0 }} loading={submitting} onClick={handleAddItem} />
                        </Modal.Footer>
                    </div>
                }
                {/* <div className='mx-4' >
          <Buttons title={'Save'} onClick={handle_photos} style={{ height: '50px', lineHeight: '50px' }} />
        </div> */}
            </Modal>
            <div className="page_container">
                <div className='' >
                    {loading ?
                        <div className='image_container model-list-main-loading-container' >
                            <div className='models_loading_container' ></div>
                            <div className='models_loading_container' ></div>
                            {width >= 400 && <div className='models_loading_container'></div>}
                            {width >= 768 && <div className='models_loading_container'></div>}
                            {width >= 900 && <div className='models_loading_container'></div>}
                        </div>
                        :
                        <div className='model-list-main-container' >
                            <Slider {..._settings} ref={sliderref}>
                                {categories.map((item, i) => (
                                    <div key={i} className='model-list-outer-container' >
                                        <div key={i} onClick={() => onCategorySelect(item, i)} className={`character_category_container_slider ${characterCategory.id == item.id && 'active'} ${i == currentSlide ? ' hover-active' : ""}`} >
                                            <div className='image_container' >
                                                <img src={item.image} width={"100%"} height={'100%'} style={{ objectFit: 'contain' }} />
                                            </div>
                                            <div className='text-center'>{item.name}</div>
                                            {item.is_level == "Y" && <div onClick={handlequest} className={'questbtn ' + (selectedQuests.length > 0 ? "active" : "")} style={{ position: 'absolute', top: 7, right: 7, fontSize: '10px' }}  >New Level <div className={"countbbl " + (selectedQuests.length > 0 ? "active" : '')} >{selectedQuests.length}</div></div>}
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    }
                    <div className='charactor_filter_container' style={{ justifyContent: characterCategory.id ? 'space-between' : 'flex-end', overflowX: 'hidden' }} >
                        {characterCategory.id &&
                            <div data-aos="fade-right" >
                                <Buttons title={characterCategory.button_text} loading={false} onClick={handleaddItem} variant={addingItem ? "primary" : "transparent"} />
                            </div>
                        }
                        <div data-aos="fade-left" className='my-4 mx-2 text-right'>
                            <select className='character_filter' value={selectedFilter} style={{ fontFamily: 'Dimbo' }} title='Filter' onChange={onFilterChange}>
                                <option className='filter_options' value={'new'}>newest</option>
                                <option className='filter_options' value={'name'} >name</option>
                                <option className='filter_options' value={'popularity'}>popularity</option>
                                {/* <option className='filter_options' style={{ display: 'none' }} selected={selectedFilter == 'default'} >default</option> */}
                            </select>
                        </div>
                    </div>
                    {addingItem &&
                        (
                            characterCategory.type == "2" ?
                                <div className='w-75 m-auto p-2 rounded my-5' style={{ backgroundColor: '#f7f7f7', border: '2px solid darkgray' }} >
                                    <div className=' w-100 md-w-75 m-auto d-flex justify-content-around ' >
                                        <Buttons style={{ height: '50px', lineHeight: '50px', margin: '10px', }} title={"Toxic " + characterCategory?.name} variant={itemsdata?.toxic ? "primary" : 'transparent'} />
                                        <Buttons style={{ height: '50px', lineHeight: '50px', margin: '10px', }} title={"Healthy " + characterCategory?.name} variant={itemsdata?.healthy ? "primary" : 'transparent'} />
                                    </div>
                                    <div className='d-flex mb-2' >
                                        <div onClick={handleItemImage} style={{ width: '50px', height: '50px', cursor: "pointer" }} className='me-2 p-2 bg-white border border-secondary rounded bg-light' >
                                            <img src={itemPhoto?.toxic?.image} style={{ objectFit: 'contain', width: '100%', height: '100%' }} />
                                        </div>
                                        <input onChange={(e) => handleChange("toxic", e.target.value)} value={itemsdata?.toxic || ""} className='border border-0 flex-1 bg-transparent text-dark w-100' placeholder='Toxic thought here' />
                                    </div>
                                    <div className='d-flex mb-2' >
                                        <div onClick={handleItemImage} style={{ width: '50px', height: '50px', cursor: "pointer" }} className='me-2 p-2 bg-white border border-secondary rounded bg-light' >
                                            <img src={itemPhoto?.healthy?.image} style={{ objectFit: 'contain', width: '100%', height: '100%' }} />
                                        </div>
                                        <input onChange={(e) => handleChange("healthy", e.target.value)} value={itemsdata?.healthy || ''} className='border border-0 flex-1 bg-transparent text-dark w-100 ' placeholder='Healthy thought here' />
                                    </div>
                                    <div className='w-100 d-flex justify-content-between' >
                                        <div className='bg-dark rounded rounded-circle text-light d-flex justify-content-center align-items-center' style={{ width: '30px', height: '30px', cursor: 'pointer' }} >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                            </svg>
                                        </div>
                                        <div onClick={submit_item} className='bg-dark rounded rounded-circle text-light d-flex justify-content-center align-items-center' style={{ width: '30px', height: '30px', cursor: 'pointer' }} >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className='w-75 m-auto p-2 rounded my-5' style={{ backgroundColor: '#f7f7f7', border: '2px solid darkgray' }} >
                                    <div className=' w-100 md-w-75 m-auto d-flex justify-content-around ' >
                                        <Buttons style={{ height: '50px', lineHeight: '50px', margin: '10px', }} title={characterCategory.name} variant={itemsdata?.toxic ? "primary" : 'transparent'} />
                                    </div>
                                    <div className='d-flex mb-2' >
                                        <div onClick={handleItemImage} style={{ width: '50px', height: '50px', cursor: "pointer" }} className='me-2 p-2 bg-white border border-secondary rounded bg-light' >
                                            <img src={itemPhoto?.photo?.image} style={{ objectFit: 'contain', width: '100%', height: '100%' }} />
                                        </div>
                                        <input onChange={(e) => handleChange("thought", e.target.value)} value={itemsdata?.thought || ""} className='border border-0 flex-1 bg-transparent text-dark w-100' placeholder={characterCategory.name + " here"} />
                                    </div>
                                    <div className='w-100 d-flex justify-content-between' >
                                        <div className='bg-dark rounded rounded-circle text-light d-flex justify-content-center align-items-center' style={{ width: '30px', height: '30px', cursor: 'pointer' }} >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                            </svg>
                                        </div>
                                        <div onClick={submit_item} className='bg-dark rounded rounded-circle text-light d-flex justify-content-center align-items-center' style={{ width: '30px', height: '30px', cursor: 'pointer' }} >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                        )
                    }
                    {
                        characterCategory.is_level == "Y" ?
                            <InfiniteScroll
                                pageStart={0}
                                loadMore={loadMore}
                                hasMore={hasMoreRef.current}
                                loader={<div className="loader d-flex justify-content-center align-items-center my-3" key={0}><Spinner /></div>}
                            >
                                {quest.map((e, i) => {
                                    if (e.type == 'M') return <NestListing i={i} e={e} />
                                    else return <QuestListing e={e} i={i} readmore={readmore} setReadMore={setReadMore} />
                                })}
                            </InfiniteScroll> :
                            <ModelListing data={data} onClick={details} updateLike={updateLike} onSort={onSort} loading={loading || filterLoading} />
                    }
                    {/* <ModelListing data={data} onClick={details} updateLike={updateLike} onSort={onSort} loading={loading || filterLoading} /> */}
                </div>
                <div style={{position:'sticky' , bottom: 20 , width: '100%', zIndex: '149'}} >
                    <div style={{ width: '70%', backgroundColor:'#fff',  minWidth: '280px', border: "2px solid #000", borderRadius: '5px', margin: '20px auto', padding: '20px', }} >
                        <form onSubmit={send} >
                            < input value={mindVal} onChange={setWhatsyourMindval} placeholder="What's on you mind?" style={{ width: '100%', border: 'none', marginBottom: '10px' }} />
                        </form>
                        <div>
                            <div className='w-100 d-flex justify-content-between' >
                                <div onClick={() => navigate("/chat/category/dashboard")} className='bg-dark rounded rounded-circle text-light d-flex justify-content-center align-items-center' style={{ width: '30px', height: '30px', cursor: 'pointer' }} >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                    </svg>
                                </div>
                                <div onClick={send} className='bg-dark rounded rounded-circle text-light d-flex justify-content-center align-items-center' style={{ width: '30px', height: '30px', cursor: 'pointer' }} >
                                    {!sending ?
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5" />
                                        </svg> :
                                        <Spinner size="sm" />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>

    )
}

export default WillHomepage
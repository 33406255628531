export const login = (payload) => { return { type: 'LOGIN', payload }; };
export const verify = (payload) => { return { type: 'VERIFY', payload }; };
export const update = (payload) => { return { type: 'UPDATE', payload }; };
export const updateProfile = (payload) => { return { type: 'UPDATE_PROFILE', payload }; };
export const updateImage = (payload) => { return { type: 'UPDATE_IMAGE', payload } }
export const updateExtra = (payload) => { return { type: 'UPDATE_EXTRA', payload }; };
export const updateSettings = (payload) => { return { type: 'UPDATE_SETTINGS', payload }; };
export const logout = () => { return { type: 'LOGOUT' }; };
export const error = (payload) => { return { type: 'ERROR', payload }; };
export const subscriotions = (payload) => { return { type: "SUBSCRIPTIONS", payload } }
export const chatsLimitDecrement = (payload) => { return { type: "DECREMENT_CHATS_SUBSCRIPTIONS", payload } }
export const ReportLimitDecrement = (payload) => { return { type: "DECREMENT_REPORTS_SUBSCRIPTIONS", payload } }
export const updateHeader = payload => ({ type: 'HEADER_STYLE', payload })
//export const counter = (payload) => { return { type:'COUNTER', payload }; };
import React , {useState , useEffect} from "react";
import { useSelector } from "react-redux";
import ChatComponent from "../components/ChatComponent";
import { useLocation } from "react-router-dom";

const PreviousChat = (props) => {

    const {form , msgfromhome} = props

    const isLogged = useSelector((state) => state.user.isLogged);
    const chats = useSelector(state => state.userchats.chats);
    const defaultCharactor = useSelector(state => state.characotors.default_charactor);
    const [chatID , setChatID] = useState(0);

    const location = useLocation()


    const [character, setCharacter] = useState(null);

    useEffect(() => {
        // alert(JSON.stringify(location))
        if(form == 'homepage'){
            setCharacter(defaultCharactor);
            setChatID(0);
        }else{

            if (character == null) {
                if (chats.length > 0) {
                    if (chats[chats?.length - 1]?.characters) {
                        setCharacter(chats[chats?.length - 1]?.characters)
                    } else {
                        setCharacter(chats[chats?.length - 1]?.character)
                    }
                    if (chats[chats?.length - 1]) {
                        setChatID(chats[chats?.length - 1].chat_id)
                    }
                }
                else {
                    setCharacter(defaultCharactor)
                }
            }
        }
    
        }, [chats])

    return (
        <div id="mynestheads_chatComponent" className="chat_page_container_static p-1 p-md-2" style={{ height: window.innerHeight - 170, border: '1px solid rgba(146, 207, 213, 1)', borderRadius: '10px', position: 'relative', overflow: 'scroll' }} >
            {/* <ChatComponent character_id={character_id} input={'static'} /> */}
            <ChatComponent showBottombutton={false} showPercentage={false} input='static' chat_id={isLogged ? chatID : 0} character_id={isLogged ? character?.id : "default"} varient="homepage" />
        </div>
    )
}

export default PreviousChat

import React, { useEffect, useState } from 'react';
import Account from './settings/Account';
import Password from './settings/Password';
import Icon from 'react-icons-kit';
import { search } from 'react-icons-kit/icomoon/search';
import { ic_lock_open } from 'react-icons-kit/md/ic_lock_open';
import { ic_settings_outline } from 'react-icons-kit/md/ic_settings_outline';
import Api from '../services/Api';
import { logout } from '../actions/userAction';
import { useDispatch } from 'react-redux';
import { Alert, Spinner } from "react-bootstrap";
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Subscription from './settings/Subscription';
import Footer from '../components/footer';

const Settings = ({ showPages = false }) => {

  const [activeTab, setActiveTab] = useState('');
  const [loading, setLoading] = useState(false);
  const [_alert, setAlert] = useState({});
  const [_helmet, setHelmet] = useState({ title: 'Nestheads - settings', des: "setting description" });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  let location = useLocation()

  const handleDelete = () => {
    setAlert({})
    setLoading(true)
    Api.post('user/delete/account').then((res) => {
      if (res.success) {
        dispatch(logout());
        navigate(`/`)

      } else {
        setAlert({ variant: 'danger', message: res.message || "Some error occured!" })
      }
    }).catch((err) => {
      setAlert({ variant: 'danger', message: err?.message || "Some Error occured please try again!" })

    }).finally(() => {
      setLoading(false)
    })
  }
  const settingsNavigation = (path) => {
    setActiveTab(path)
    navigate(`/chat/settings/${path}`)
  }

  useEffect(() => {

    if (location.pathname.includes("account")) {
      setActiveTab('account')
    } else if (location.pathname.includes('password')) {
      setActiveTab("password")
    } else if (location.pathname.includes('delete')) {
      setActiveTab("delete")
    }
    else if (location.pathname.includes('subscriptions')) {
      setActiveTab("subscriptions")
    }
    else if(!location.pathname.includes('settings')) {
      setActiveTab("")
    }

  }, [location])

  return (
    <div className=''   >
      <div className='page_container settings' >
        <Helmet>
          <title>{_helmet.title}</title>
          <meta name='description' content={_helmet.des} />
        </Helmet>
        <div className='setting-tabs-container' >
          <a href={`/chat/settings/account`} style={{ textDecoration: 'none' }} onClick={e => e.preventDefault()} ><div onClick={() => settingsNavigation('account')} className={`${activeTab == "account" ? "setting-tabs-active" : 'setting-tabs'}`}><div style={{ marginRight: 5, display: 'inline' }} ><Icon icon={search} /> </div>ACCOUNT</div></a>
          <a href={`/chat/settings/password`} style={{ textDecoration: 'none' }} onClick={e => e.preventDefault()} ><div onClick={() => settingsNavigation('password')} className={`${activeTab == "password" ? "setting-tabs-active" : 'setting-tabs'}`}><div style={{ marginRight: 5, display: 'inline' }} ><Icon icon={ic_lock_open} /> </div>PASSWORD</div></a>
          <a href={`/chat/settings/subscriptions`} style={{ textDecoration: 'none' }} onClick={e => e.preventDefault()} ><div onClick={() => settingsNavigation('subscriptions')} className={`${activeTab == "subscriptions" ? "setting-tabs-active" : 'setting-tabs'}`}><div style={{ marginRight: 5, display: 'inline' }} >{activeTab == "subscriptions" ? <img src={require('../assets/subscriptions-active.png')} alt='subscriptions logo' width={16} /> : <img src={require('../assets/subscriptions.png')} alt='subscription not active' width={16} />}</div>SUBSCRIPTIONS</div></a>
          <a href={`/chat/settings/delete`} style={{ textDecoration: 'none' }} onClick={e => e.preventDefault()} ><div onClick={() => settingsNavigation('delete')} className={`${activeTab == "delete" ? "setting-tabs-active" : 'setting-tabs'}`}><div style={{ marginRight: 5, display: 'inline' }} ><Icon icon={ic_settings_outline} /> </div>SETTINGS</div></a>
        </div >
        {_alert?.message && <Alert dismissible variant={_alert.variant}>{_alert.message}</Alert>}
        <div style={{display: showPages ? "initial" : "none"}} >
          <div style={{ padding: '30px 20px', marginBottom: '30px', boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",  }}  >
            {
              activeTab == 'account' ? <Account /> :
                activeTab == 'password' ? <Password /> :
                  activeTab == 'subscriptions' ? <Subscription /> :
                    <>
                      <div style={{ textAlign: 'center', paddingBottom: '20px ' }}> Are you sure you want to delete your account?  </div>
                      <div className='settings-bottom-btn' >
                        <div style={{ padding: ' 0px 10px', display: 'flex', justifyContent: 'flex-end', fontFamily: 'Dimbo' }} >
                          <div onClick={handleDelete} style={{ padding: '10px 30px', backgroundColor: 'black', borderRadius: '25px', color: 'white', minWidth: '156px', minHeight: '47px', textAlign: 'center', cursor: 'pointer' }}>{loading ? <Spinner size='sm' /> : 'Yes, Delete Account'}</div>
                        </div>
                        <div className='settings-btn-cancle'  >
                          <div onClick={() => navigate('/')} style={{ padding: '10px 30px', borderRadius: '25px', border: '2px solid black', width: '156px', textAlign: 'center', cursor: 'pointer', fontFamily: 'Dimbo' }}>Cancel</div>
                        </div>
                      </div>
                    </>
            }
          </div>
        </div>
      </div >
      {/* <Footer /> */}
    </div>
  );
};

export default Settings;
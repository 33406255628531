import React, { useEffect, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import Api from "../services/Api";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { setQuestsdata } from "../actions/questActions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const QuestionSection = ({ question , OpenSidebar }) => {
    return (
        <div style={{ minWidth: '280px', width: '70%', margin: 'auto' }}>
            <div className="wake_up_will_questions" style={{  backgroundColor : "#ccc",  padding: "40px 30px", borderRadius: 30 , position:'relative' }} >
                <h2 style={{ fontSize: '35px', fontFamily: "Dimbo", textAlign: 'center' }} >{!question ? "what is on your mind?" : question.title}</h2>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', }}  >
                <img onClick={OpenSidebar} className="me-sm-0 me-md-5" src={require('../assets/will_right.png')} style={{ width: '100px', height: '100px', zIndex:10 }} />
            </div>
        </div>
    )
}

const WakeUpWill = () => {

    const navigate = useNavigate();
    const [questions, setQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [response, setResponse] = useState('');
    const [submitting, setSubmitting] = useState(false);

    const dispatch = useDispatch();
    const location = useLocation()

    const isLogged = useSelector(state=> state.user.isLogged)
    const { from = null, redirectmessage = null, redirectdata = {} } = location?.state || {}

    const OpenSidebar = () => {
        if(isLogged){
            dispatch({type: "HEADER_STYLE" , payload: {openSidePanal : true}})
        }
    }

    const getQuestion = () => {
        Api.get("question/list")
            .then(res => {
                if (res.success) {
                    if(res.data.length != 0) {
                        setQuestions(res.data)
                    }else{
                        setQuestsdata({id:1 , title: "What's on your mind?"})
                    }
                    setCurrentQuestionIndex(0)
                }
            })
    }

    useEffect(() => {

        if(redirectmessage){
            setResponse(redirectmessage)
        }

        dispatch({type: "HEADER_STYLE" , payload: {showCenterButton: false , border: false}})
        getQuestion()

        return () => dispatch({type: "HEADER_STYLE" , payload: {showCenterButton: true , border: true}})
    }, [])

    const setResponseValue = (e) => {
        setResponse(e.target.value)
    }

    const submit_item = (e) => {
        e.preventDefault()

        if(!isLogged){
            navigate("/login" , {state: {from: 'wakeupyourwill' , redirectmessage : response}})
            return
        }

        if(response.trim() == "" || submitting){
            return
        }

        setSubmitting(true)
        Api.post("question/" + questions[currentQuestionIndex].id + "/answer" , {
            "q_id": questions[currentQuestionIndex].id,
            "response": response,
            "character_id": 31
        }).then(res => {
            if(res.success){
                setResponse("")
                if(questions.length == currentQuestionIndex + 1){
                    setCurrentQuestionIndex(0);
                }else{
                    setCurrentQuestionIndex(currentQuestionIndex + 1 )
                }
            }
        }).catch((err)=> {console.log(err)})
        .finally(()=> {
            setSubmitting(false)
        })

    }

    return (
        <div className="page_container" style={{marginTop: '80px'}} >
            <div style={{ display: 'flex',marginBottom: 5,  justifyContent: 'space-between', flexDirection: 'column', height: window.innerHeight - 140 }}>
                <div>
                    <QuestionSection question={questions[currentQuestionIndex]} OpenSidebar={OpenSidebar} />
                </div>
                <div >
                    <div style={{ width: '70%', minWidth: '280px', border: "2px solid #000", borderRadius: '5px', margin: '20px auto', padding: '20px', }} >
                        <form onSubmit={submit_item} >
                        <input value={response} onChange={setResponseValue} placeholder={questions?.[currentQuestionIndex]?.title} style={{ width: '100%', border: 'none', marginBottom: '10px' }} />
                        </form>
                        <div>
                            <div  className='w-100 d-flex justify-content-between' >
                                <div onClick={() => navigate("/chat/category/dashboard")} className='bg-dark rounded rounded-circle text-light d-flex justify-content-center align-items-center' style={{ width: '30px', height: '30px', cursor: 'pointer' }} >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                    </svg>
                                </div>
                                <div onClick={submit_item} className='bg-dark rounded rounded-circle text-light d-flex justify-content-center align-items-center' style={{ width: '30px', height: '30px', cursor: 'pointer' }} >
                                    {!submitting ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5" />
                                    </svg> : 
                                    <Spinner size="sm"/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{position: 'fixed' , left: 0, right:0 , bottom: 0,margin: "2px 4px",  display: 'flex' , justifyContent: 'space-between'}} >
                <div style={{fontFamily: 'Dimbo' , fontSize: '12px'}} >2021-{new Date().getFullYear()} Nestheads lnc.</div>
                <div style={{fontFamily: 'Dimbo' , fontSize: '12px'}} ><a style={{textDecoration:'none'}} className="text-dark" target="_blank" href="https://nestheads.com/privacy-policy/" >Privacy Policy</a> and <a style={{textDecoration:'none'}} className="text-dark" target="_blank" href="https://nestheads.com/term-of-use/" >Terms of use</a></div>
            </div>
        </div>
    )
}

export default WakeUpWill
import React, { useState, useEffect } from "react";
import '../App.css';
import logo from '../assets/logo.png';
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Api from "../services/Api";
import { logout } from "../actions/userAction";
import { Container, Dropdown } from "react-bootstrap";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { setCategoriesdata } from "../actions/categoriesActions";

import { Link } from "react-router-dom";
import { setChatdata } from "../actions/chatActions";
import { resetQuests } from "../actions/questActions";
import CustomDropdown from "./CustomDropdown";
import { toast } from "react-toastify";

let defaultimg = require('../assets/user.png');

const Header = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state?.user);
    const [active, setActive] = useState('register');
    const [sideBar, setSideBar] = useState({ isPaneOpen: false })
    const [activeCategory, setActiveCategory] = useState('')
    const [categories, setCategories] = useState([])
    const [select, setSelect] = useState('')

    const _categories = useSelector(state => state.category.categories)
    const isLogged = useSelector(state => state.user.isLogged)
    const selectedQuests = useSelector(state => state.quests.selected)
    const header = useSelector(state => state.user.header)

    let location = useLocation();
    const { from, message } = location?.state || { from: "" }

    const logOut = () => {
        Api.get('auth/logout').then(res => {
            if (res) {
                setSideBar({ isPaneOpen: false })
                dispatch(logout())
                localStorage.removeItem('charactersItems')
                localStorage.removeItem('charactersdata')
                dispatch(setChatdata([]))
                dispatch(resetQuests())
                navigate('/login')
            }
        });
    }

    const navigateto = (route) => {
        navigate('/' + route, { state: { from: from, message: message } })
        setActive(route)
    }

    useEffect(() => {
        if (header?.openSidePanal) {
            setSideBar({ isPaneOpen: true })
        }else{
        }
    }, [header.openSidePanal])

    const handleSideBarClick = (e) => {
        setActiveCategory(e.name)
        setSideBar({ isPaneOpen: false })
        if (e.name == 'nestheads') {
            navigate('/chat/category/nestheads')
            return
        }
        if (e.name == 'quests') {
            navigate('/chat/category/quests')
            return
        }
        if (e.name == 'dashboard') {
            navigate('/chat/category/dashboard')
            return
        }
        navigate('/chat/category/' + e.id + "-" + e.name.toLowerCase().replace(" ", "_"))
    }


    useEffect(() => {
        setActiveCategory('')
        let _loc = location.pathname.split("/");
        if (_loc[2] == 'category') {
            if (_loc[3]) {
                setActiveCategory(_loc[3].toLowerCase())
            } else {
                setActiveCategory('')
            }
        } else if (_loc[1] == 'chat') {
            setActiveCategory('quests')
        }
        let url = location.pathname.slice(1, location.pathname.length)
        if (url == 'login') {
            setActive(url)
        } else {
            setActive("register")
        }
    }, [location])

    useEffect(() => {
        setCategories(_categories)
    }, [_categories])

    useEffect(() => {
        if (user.isLogged) {
            Api.get('chat-category/list')
                .then((res) => {
                    if (res.success) {
                        dispatch(setCategoriesdata(res.data))
                    }
                })
                .catch((err) => {

                })
        }
    }, [user.isLogged])


    useEffect(() => {
        let _url = location.pathname.slice(1, location.pathname.length)
        let splice = _url.split("/")
        let url = splice[0]
        if (url) {
            if (url == 'login') {
                setSelect('')
            } else {
                setSelect(url)
            }
        } else {
            setSelect("discovery")
        }
    }, [location])

    const logo_click = () => {
        setSideBar({ isPaneOpen: false })
        navigate('/')
    }

    const handlequest = (event) => {
        event.preventDefault();
        if (selectedQuests.length) {
            if (!isLogged) {
                navigate('/login', { state: { from: "/create-level" } })
                // navigate('/' + route, { state: { from: from, message: message } })

            } else {
                navigate('/create-level', { state: { quests: [...selectedQuests] } })
            }
        }
    }

    const setTabSort = (e, id, sort) => {
        e.stopPropagation();

        let _data = [...categories]
        let index = _data.findIndex(item => item.id == id)

        // console.log(index)

        if ((index == 0 && sort == "up") || (index == (_data.length - 1) && sort == 'down')) {
            toast.error(`No item is ${sort == "down" ? "below" : "above"}!`)
            return
        }

        Api.get("chat/tag-order/" + id + "/" + sort)
            .then((res) => {
                if (res.success) {


                    if (sort == "up") {
                        let before = _data[index]
                        _data[index] = _data[index - 1]
                        _data[index - 1] = before
                    } else {
                        let before = _data[index]

                        _data[index] = _data[index + 1]
                        _data[index + 1] = before
                    }
                    setCategories(_data)
                } else {
                    toast.error(res?.message || "Some Error Occured!")
                }
            })
            .catch((err) => {
                toast.error(err?.message || "Some Error Occured!")
            })
    }

    const handleSidebarClose = () => {
        setSideBar({ isPaneOpen: false })
        dispatch({type: "HEADER_STYLE" , payload: {openSidePanal : false}})
    }

    return (<>
        <SlidingPane
            className="side_bar"
            overlayClassName="side_bar_background"
            isOpen={sideBar.isPaneOpen}
            from='right'
            width="100%"
            hideHeader
            onRequestClose={() => {
                setSideBar({ isPaneOpen: false });
                dispatch({type: "HEADER_STYLE" , payload: {openSidePanal : false}})
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }} >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 20, }} >
                    {/* <img src={require('../assets/logo.png')} width={150} style={{ cursor: 'pointer' }} onClick={logo_click} /> */}
                    <img src={require('../assets/cross.png')} style={{ position: 'absolute', top: 20, right: 20, width: '20px', cursor: 'pointer' }} onClick={handleSidebarClose} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', flex: 1 }}>
                    <div>
                        <CustomDropdown>
                            <div style={{ cursor: 'pointer', margin: "5px 0px" }} onClick={() => handleSideBarClick({ name: 'dashboard' })}>
                                <div style={{ borderRadius: 2, backgroundColor: activeCategory.toLowerCase() == 'dashboard' ? "rgba(146, 207, 213, 1)" : "transparent", padding: 10, display: 'flex', justifyContent: 'space-between' }} >
                                    <div style={{ display: 'flex' }}>
                                        <img src={require('../assets/will thumbs up.png')} width={25} height={25} style={{ objectFit: 'contain' }} />
                                        <div style={{ margin: '0 10px', color: activeCategory.toLowerCase() == 'dashboard' ? "#000" : "rgba(188, 187, 199, 1)" }}>Dashboard</div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ cursor: 'pointer', margin: "5px 0px" }} onClick={() => handleSideBarClick({ name: 'nestheads' })}>
                                <div style={{ borderRadius: 2, backgroundColor: activeCategory.toLowerCase() == 'nestheads' ? "rgba(146, 207, 213, 1)" : "transparent", padding: 10, display: 'flex', justifyContent: 'space-between' }} >
                                    <div style={{ display: 'flex' }}>
                                        <img src={require('../assets/map.png')} width={25} height={25} style={{ objectFit: 'contain' }} />
                                        <div style={{ margin: '0 10px', color: activeCategory.toLowerCase() == 'nestheads' ? "#000" : "rgba(188, 187, 199, 1)" }}>NEST</div>
                                    </div>
                                </div>
                            </div>
                            {<div style={{ cursor: 'pointer', margin: "5px 0px" }} onClick={() => handleSideBarClick({ name: 'quests' })}>
                                <div style={{ borderRadius: 2, backgroundColor: activeCategory.toLowerCase() == 'quests' ? "rgba(146, 207, 213, 1)" : "transparent", padding: 10, display: 'flex', justifyContent: 'space-between' }} >
                                    <div style={{ display: 'flex' }}>
                                        <img src={require('../assets/quest.png')} width={25} height={25} style={{ objectFit: 'contain' }} />
                                        <div style={{ margin: '0 10px', color: activeCategory.toLowerCase() == 'quests' ? "#000" : "rgba(188, 187, 199, 1)" }}>Quests</div>
                                    </div>
                                </div>
                            </div>}
                            {categories.map((e, i) => {
                                return (
                                    <div key={i} className="sidebar-sorting-buttons-container" style={{ cursor: 'pointer', margin: "5px 0px" }} onClick={() => handleSideBarClick(e)}>
                                        <div style={{ borderRadius: 2, backgroundColor: activeCategory?.replace("_" , " ").split("-")[1] == e.name.toLowerCase() ? "rgba(146, 207, 213, 1)" : "transparent", padding: 10, display: 'flex', justifyContent: 'space-between' }} >
                                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    {/* <div className="sidebar-sorting-buttons" style={{ marginRight: '5px' }} >
                                                    <div onClick={(event) => setTabSort(event, e.id, "up")} style={{ height: "10px", width: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                                        <img src={require('../assets/up1.png')} style={{ height: '100%', width: '100%' }} />
                                                    </div>
                                                    <div onClick={(event) => setTabSort(event, e.id, "down")} style={{ height: "10px", width: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                                        <img src={require('../assets/down1.png')} style={{ height: '100%', width: '100%' }} />
                                                    </div>
                                                </div> */}
                                                    <img src={e.image} width={25} height={25} style={{ objectFit: 'contain' }} />
                                                </div>
                                                <div style={{ margin: '0 10px', color: activeCategory?.replace("_" , " ").split("-")[1] == e.name.toLowerCase() ? "#000" : "rgba(188, 187, 199, 1)" }}>{e.name}</div>
                                                <div style={{ flex: 1, textAlign: "end", color: "#fff" }} >{e.count}</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </CustomDropdown>
                    </div>
                    <div style={{ cursor: 'pointer' }} onClick={logOut}>
                        <div style={{ backgroundColor: "transparent", padding: 10, display: 'flex', justifyContent: 'space-between' }} >
                            <div style={{ display: 'flex' }} >
                                <img src={require('../assets/logout.png')} width={25} />
                                <div style={{ margin: '0 10px', color: 'rgba(188, 187, 199, 1)' }}>Logout</div>
                            </div>
                            {/* <img src={require('../assets/google.png')} width={25} /> */}
                        </div>
                    </div>
                </div>
            </div>
        </SlidingPane >
        <div className={`logo ${(isLogged && header.border) && 'border-bottom'}`} >
            <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                <img className="logo-img" onClick={() => navigate('/')} style={{ cursor: 'pointer' }} src={logo} width={180} height={50} alt="Nestheads logo" />
                {/* {user?.isLogged && <div onClick={() => setSideBar({ isPaneOpen: true })} style={{ width: 'fit-content', margin: '0 10px', cursor: 'pointer' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
                    </svg>
                </div>} */}
            </div>
            {/* {header.showCenterButton && <div className=" d-none d-md-flex justify-content-center align-items-center" style={{ flex: 1 }}>
                <Link className={`${select === 'discovery' ? 'header-link-active' : "header-link"}`} to={'/'} onClick={() => setSelect('discovery')}>Discovery</Link>
                <Link to={'/'} className={'questbtn ' + (selectedQuests.length > 0 ? "active" : "")} onClick={handlequest}>New Level<div className={"countbbl " + (selectedQuests.length > 0 ? "active" : '')} >{selectedQuests.length}</div></Link>
                <Link className={`${select === 'models' ? 'header-link-active' : "header-link"}`} to={"/models"} onClick={() => setSelect('models')}>Characters</Link>
            </div >} */}
            {
                user?.isLogged ?
                    <div style={{ width: 'fit-content', display: 'flex', alignItems: 'center', marginRight: '5px', flex: 1, justifyContent: 'flex-end' }
                    } >
                        {/* <Dropdown> */}
                        {/* <Dropdown.Toggle style={{ backgroundColor: 'transparent', color: 'black', borderColor: "transparent", margin: '0px', }}> */}
                        {/* <div style={{ display: 'inline', fontFamily: 'Dimbo' }} >{user?.data?.first_name || ""} </div>  */}
                        <div onClick={() => setSideBar({ isPaneOpen: true })} style={{ width: 40, height: 40, borderRadius: '50%', overflow: 'hidden' }} >
                            <img style={{ cursor: 'pointer' }} referrerPolicy="no-referrer" src={user?.data?.photo ? user.data.photo : defaultimg} width='100%' height='100%' alt="Profile picture" />
                        </div>
                        {/* </Dropdown.Toggle> */}
                        {/* <Dropdown.Menu>
                                <Dropdown.Item style={{ fontFamily: 'Dimbo' }} onClick={() => navigate('./settings')}>Setting</Dropdown.Item>
                                <Dropdown.Item style={{ fontFamily: 'Dimbo' }} onClick={logOut}>Logout</Dropdown.Item>
                            </Dropdown.Menu> */}
                        {/* </Dropdown> */}
                        {/* <div style={{ width: 40, height: 40, borderRadius: '50%', overflow: 'hidden', marginLeft: '10px' }} >
                            <img onClick={() => navigate('/settings')} style={{ cursor: 'pointer' }} referrerPolicy="no-referrer" src={user?.data?.photo ? user.data.photo : defaultimg} width='100%' height='100%' alt="Profile picture" />
                        </div> */}
                    </div >
                    :
                    <div className="header-right" style={{ display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'flex-end' }}>
                        <div style={{ cursor: 'pointer' }} className={active === 'login' ? 'active' : 'header-right-btn'} onClick={() => navigateto('login')}>Sign In</div>
                        <div style={{ cursor: 'pointer' }} className={active === 'register' ? 'active' : 'header-right-btn'} onClick={() => navigateto('register')}>Sign Up</div>
                    </div>
            }
        </div >
        {/* {header.showCenterButton && <div className={`d-flex d-md-none justify-content-center align-items-center py-3 ${(isLogged && header.border) ? "border-bottom" : "border"}`} style={{ flex: 1, background: '#fff' }}>
            <Link className={`${select === 'discovery' ? 'header-link-active' : "header-link"}`} to={'/'} onClick={() => setSelect('discovery')}>Discovery</Link>
            <Link to={'/'} className={'questbtn ' + (selectedQuests.length > 0 ? "active" : "")} onClick={handlequest}>New Level <div className={"countbbl " + (selectedQuests.length > 0 ? "active" : '')} >{selectedQuests.length}</div></Link>
            <Link className={`${select === 'models' ? 'header-link-active' : "header-link"}`} to={"/models"} onClick={() => setSelect('models')}>Characters</Link>
        </div>} */}
    </>
    )
}

export default Header;
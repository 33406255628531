import React, { useEffect, useState } from "react";
import ChatComponent from "./ChatComponent";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

const _nestheads = [
    {
        image: null,
        title: 'Nestheads Found!',
        total: 16
    },
]

let sectionClosed = require('../assets/sectionClosed.png')
let sectionOpened = require('../assets/sectionOpened.png')

const MyNestheads = ({ categoryTitle }) => {

    const [charactorID, setCharatorID] = useState(null)
    const [character_id, setCharacter_id] = useState(0)
    const [nestheads, setNestheads] = useState([])
    const [openedSections, setOpenedSections] = useState([])
    const [validChats, setValidChats] = useState([])

    const totalChats = useSelector(state => state.userchats.chats)
    const defaultCharacter = useSelector(state => state.characotors.default_charactor)

    useEffect(() => {
        if (totalChats.length) {
            let valid = []
            totalChats.map((e) => {
                if (e?.chat_messages) {
                    valid.push(e)
                }
            })
            setValidChats(valid)
        }
    }, [totalChats])

    useEffect(() => {
        setNestheads(_nestheads)
    }, [_nestheads])

    useEffect(() => {
        if (defaultCharacter) {
            setCharacter_id(defaultCharacter.id)
        }
    }, [defaultCharacter])
    return (
        <div className='mynestheads_container' style={{marginTop: '10px'}} >
            <Helmet>
                <title>{'Nestheads - My Nestheads'}</title>
            </Helmet>
            <div style={{ backgroundColor: '#fff' }} >
                <div id="mynestheads_chatComponent" className="chat_page_container_static p-1 p-md-2" style={{ height: '550px', border: '1px solid rgba(146, 207, 213, 1)', borderRadius: '10px', position: 'relative', overflow: 'scroll' }} >
                    <ChatComponent character_id={character_id} input={'static'} />
                </div>
                <div style={{ fontFamily: 'Dimbo', fontSize: '28px', marginTop: 20 }} >My <span style={{ textTransform: 'capitalize' }} >{categoryTitle}</span></div>
                <div style={{ margin: '20px 0px' }}>
                    {nestheads.map((e, i) => {
                        return (
                            <div key={i} style={{ margin: '15px 0px', border: '2px solid rgba(146, 207, 213, 1)', borderRadius: '8px', padding: "10px 15px", height: openedSections.includes(i) ? "auto" : 53.2, overflow: 'hidden' }} >
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center ' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ fontFamily: "Dimbo", fontSize: '20px', height: '30px', minWidth: '80px', overflow: 'hidden' }} >{e.title}</div>
                                        <div style={{ border: '1px solid rgba(146, 207, 213, 1)', textAlign: 'center', borderRadius: '20px', height: '27px', width: '27px', lineHeight: '27px', marginLeft: '10px', backgroundColor: 'black', color: 'white', fontSize: '10px' }} >{validChats.length}</div>
                                    </div>
                                    {/* <div style={{ minWidth: 52 }}>
                                    <img src={require('../assets/delete_quest.png')} style={{ width: '18px', height: '18px', marginRight: '15px', cursor: "pointer" }} />
                                    <img onClick={() => handleToggleSections(i)} src={openedSections.includes(i) ? sectionOpened : sectionClosed} style={{ width: '18px', cursor: "pointer" }} />
                                </div> */}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default MyNestheads